import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Employee } from '../shared/employee.model';
import { map } from 'rxjs/operators';
import { EmpresaService } from '../shared/empresa.service';
import { Empresa } from '../shared/empresa.model';



@Injectable()
export class AuthService {


  user$: Observable<Employee>;

  constructor(private router: Router, private afsAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private serviceEmp: EmpresaService) {
    //this.authEmp();
    this.query2();
  }

  //Metodo para registrar usuario
  registerUser(correo: string, contra: string) {
    return new Promise(() => {
      this.afsAuth.auth.createUserWithEmailAndPassword(correo, contra)
    });
  }

  // Metodo para iniciar session
  loginEmailUser(correo: string, contra: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.signInWithEmailAndPassword(correo, contra)
        .then(userData => resolve(userData),
          err => reject(err));
    });

  }

  logoutUser() {
    localStorage.removeItem('usuario');
    this.router.navigate(['/']);
    return this.afsAuth.auth.signOut();
  }

  authEmp() {
    let empresa
    this.serviceEmp.getEmpresas().subscribe(actionArray => {
      empresa = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as Empresa;
      })
    });

  }

  query2() {
    this.afs.firestore.collection('empresas')
      .where('correo1', '==', 'jmas@jmas.com')
      .get()
      .then(querySnapshot => {
        let correos: any[] = [];
        querySnapshot.forEach(function (doc) {
          correos.push(
            doc.data().correo1,
            doc.data().contrasena);
        });
        // if(correos[0]==){

        // }
      });
  }




}
