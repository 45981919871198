import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PDFService {
  protected url:string = 'https://lab.jlabogados.com.mx/API/pdfAPI/';

  constructor( private http:HttpClient ) {}

  subirImagen(datos:any):Observable<any>{
    return this.http.post(this.url, datos);
  }
}