import { Component } from '@angular/core';

import { StateService } from '../../../@core/utils';
import { NotificacionesService } from '../../../shared/notificaciones.service';
import { Notificacion } from '../../../shared/notificacion.model';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';


@Component({
  selector: 'ngx-theme-settings',
  styleUrls: ['./theme-settings.component.scss'],
  templateUrl: './theme-settings.component.html',
})
export class ThemeSettingsComponent {

  notificacionesArr: any[] = [];
  colorBarraSuperior: string = "info";
  correoDelUsuario: string = JSON.parse(localStorage.getItem('usuario')).email;


  constructor(protected stateService: StateService,
    private notificacionesService: NotificacionesService,
    public router:Router
  ) {

  }

  ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getAllNotificaciones();
  }

  sortByKeyDec(array:any[], key:string){
    return array.sort(function(a, b) {
        var x = b[key]; var y = a[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getAllNotificaciones() {
    let self = this;
    this.notificacionesService.getNotificaciones().subscribe(actionArray => {
      this.notificacionesArr = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as Notificacion;
      })
      this.notificacionesArr = this.notificacionesArr.filter(notificacion => notificacion.usuario == self.correoDelUsuario);
      this.notificacionesArr = this.sortByKeyDec(this.notificacionesArr, 'fechaCreacion');
    });
  }

  showAlert() {
    Swal.fire({
      title: '<strong>Sucesos:{{Tal Suceso}}</strong>',
      html:
        '<div class="form-group">' +
    '<div class="form-row">' +
        '<div class="col-md-6">' +
            '<h2>'+'Control: '+' </h2>'+
        '</div>'+
        '<div class="col-md-6">' +
            '<h2>'+'#: '+'</h2>'+
        '</div>' +
    '</div>' +
'</div>'+ 

'<div class="form-group">' +
                '<h2>'+'Suceso: '+' </h2>'+
'</div>'+ 

'<div class="form-group">' +
        '<div class="form-row">' +
            '<div class="col-md-12">' +
                '<button>'+' Descargar'+'</button>'+
            '</div>'+
        '</div>' +
'</div>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Listo',
      confirmButtonAriaLabel: 'Listo',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Cerrar',
    })
  }


  showTareaAlert() { 
    Swal.fire({
      title: '<strong>Tarea:{{Tal Tarea}}</strong>',
      html:
      '<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">'+   
      '<div class="form-row">'+
        '<div class="form-group col-md-6">'+
          '<label for="sel1">Seleccionar Control:</label>'+
        '</div>'+
        '<div class="form-group col-md-6">'+
          '<label for="sel1">Seleccionar Empresa:</label>'+
        '</div>'+
      '</div>'+
      '<div class="form-group">'+
        '<label for="fechaasig"> Fecha de Asignación</label>'+
      '</div>'+
      '<div class="form-group">'+
        '<label for="fechaasig"> Fecha Límite</label>'+ 
      '</div>'+
      '<div class="form-group">'+
      '</div>'+
        '<div class="form-group  col-md-12">'+
          '<label for="sel1">Responsable:</label>'+
      '</div>'+
    '</form>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Listo',
      confirmButtonAriaLabel: 'Listo',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Cerrar',
    })
  }

  ir(notificacion:any){
    this.router.navigate(['/pages/tareas'], { queryParams: { control: notificacion.control } });
  }

}
