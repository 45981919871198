import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Employee } from './employee.model';
import { of as observableOf,  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  formData: Employee;


  constructor(public firestore: AngularFirestore) { }


  getEmployees() {
    return this.firestore.collection('usuarios').snapshotChanges();
  }

  getUsers(): Observable<any> {
    return observableOf(this.formData);
  }
  
}
