import { Component, Input, OnInit, Inject } from '@angular/core';

import { NbSidebarService, NbMenuService, NB_WINDOW } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { EmployeeService } from '../../../shared/employee.service';
import { Employee } from '../../../shared/employee.model';
import { Observable } from 'rxjs/Observable';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../../../core/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { UserData } from '../../../@core/data/users';
import { NotificacionesService } from '../../../shared/notificaciones.service';
import { Notificacion } from '../../../shared/notificacion.model';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  
  usuario: any;
  user: any;
  nombreDelUsuario:string = '';
  correoDelUsuario:string = JSON.parse(localStorage.getItem('usuario')).email;
  usuarioFromLocalStorage:any = JSON.parse(localStorage.getItem('usuario'));

  usuarioMenu = [{title: 'Cerrar Sesión'}];
  
  notificacionesArr: any[]=[];
  sidebarEnd = false;
  expanded = false;
  wasExpanded = false;

  constructor(private sidebarService: NbSidebarService,
              private layoutService: LayoutService,
              private usuarioService: EmployeeService,
              private nbMenuService: NbMenuService, 
              private authService: AuthService,
              private userService: UserData,
              private afsAuth: AngularFireAuth, private afs: AngularFirestore,
              @Inject(NB_WINDOW) private window,
              private notificacionesService : NotificacionesService) {
                        
  }

  ngOnInit() {
    this.userService.getUsers()
    .subscribe((users: any) => this.user = users.nick);
      // this.usuarioService.getUsers()
      // .subscribe((usuario:any) => this.usuario = usuario.id)
      
      this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => this.authService.logoutUser());
      this.getAllNotificaciones();
      this.getNombreDeUsuario();
  }

  protected getNombreDeUsuario(){
    if(this.usuarioFromLocalStorage.hasOwnProperty('apaterno')){
      this.nombreDelUsuario = this.usuarioFromLocalStorage.nombre + ' ' + this.usuarioFromLocalStorage.apaterno;
    }
    else{
      this.nombreDelUsuario = this.usuarioFromLocalStorage.nombre;
    }
  }

  getAllNotificaciones(){ 
    this.notificacionesService.getNotificaciones().subscribe(actionArray => {
      this.notificacionesArr = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as Notificacion;
      })
      this.notificacionesArr = this.notificacionesArr.filter(notificacion => notificacion.usuario==this.correoDelUsuario);
      let hoy = new Date();
      let ayer = hoy.setDate(hoy.getDate() - 1);
      let ayerString = this.formatDate(new Date(ayer));
      let notificacionesParaBorrar : any[] = this.notificacionesArr.filter(notificacion => notificacion.fechaAbierto != '' && notificacion.fechaAbierto <= ayerString);
      this.deleteNotificaciones(notificacionesParaBorrar);
    });
  }

  deleteNotificaciones(notificaciones:any[]){
    notificaciones.map(notificacion => {
      this.afs.doc('notificaciones/' + notificacion.id).delete();
    });
  }

  formatDate(fecha:Date) {
    let fechaToReturn : string;
    if(fecha!=null){
        let mes = (fecha.getMonth() + 1).toString();
        let dia = fecha.getDate().toString();
        if(mes.length<2) mes = '0' + mes;
        if(dia.length<2) dia = '0' + dia;
        fechaToReturn = `${fecha.getFullYear()}-${mes}-${dia}`;
    }
    else{
        fechaToReturn = '';
    }
    return fechaToReturn;
  } 


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings() {
    this.sidebarService.toggle(false, 'settings-sidebar');
    this.expanded = !this.expanded;
    this.wasExpanded = true;
  }

}
