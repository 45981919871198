import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HeaderComponent, ThemeSettingsComponent } from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {OneColumnLayoutComponent,SampleLayoutComponent} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { LimitarcharPipe } from '../shared/pipes/limitchar.pipe';
import { NotificacionesactivasPipe } from '../shared/pipes/notificacionesactivas.pipe';
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbStepperModule,
  NbButtonModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbListModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbSpinnerModule,
  NbSelectModule,
  NbChatModule,
  NbBadgeModule
} from '@nebular/theme';
import { FechaCreacionPipe } from '../shared/pipes/fechacreacion.pipe';
import { ModalTipoComponent } from './components/modal-tipo/modal-tipo.component';


const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
  NbCardModule,
  NbBadgeModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NgbModule,
  NbProgressBarModule,
  NbStepperModule,
  NbButtonModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbSpinnerModule,
  NbSelectModule,
  NbListModule,
];

const COMPONENTS = [
  HeaderComponent,  
  ThemeSettingsComponent,
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ModalTipoComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  LimitarcharPipe,
  NotificacionesactivasPipe,
  FechaCreacionPipe
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [ DEFAULT_THEME ],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES]
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
