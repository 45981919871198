import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificacionesService {
  notificaciones:any;
  constructor(public firestore: AngularFirestore, public toastr : ToastrService) { }

  getNotificaciones() {
    return this.firestore.collection('notificaciones').snapshotChanges();
  }

  updateNotificacion(notificacion:any){
    this.firestore.doc('notificaciones/' + notificacion.id).update(notificacion);
  }

  deleteNotificacion(notificacion:any){
      this.firestore.doc('notificaciones/' + notificacion.id).delete();
  }

  insertNotificacion(notificacion:any){
    this.firestore.collection('notificaciones').add(notificacion);
  }

  setNotificacionAbierta(notificacion:any){
    notificacion.fechaAbierto = this.formatDate(new Date());
    this.setToast(notificacion);
    this.updateNotificacion(notificacion);
  }

  setToast(notificacion:any){
    this.toastr.success(notificacion.contenido, notificacion.titulo);
  }

  formatDate(fecha:Date) {
    let fechaToReturn : string;
    if(fecha!=null){
        let mes = (fecha.getMonth() + 1).toString();
        let dia = fecha.getDate().toString();
        if(mes.length<2) mes = '0' + mes;
        if(dia.length<2) dia = '0' + dia;
        fechaToReturn = `${fecha.getFullYear()}-${mes}-${dia}`;
    }
    else{
        fechaToReturn = '';
    }
    return fechaToReturn;
  } 

}
