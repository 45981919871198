import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'fechaCreacionPipe',
    pure: false
})
export class FechaCreacionPipe implements PipeTransform {

    transform(texto: string='') {
        const formatDate = (fecha:Date) => {
            let fechaToReturn : string;
            if(fecha!=null){
                let mes = (fecha.getMonth() + 1).toString();
                let dia = fecha.getDate().toString();
                let hora = fecha.getHours().toString();
                let minutos = fecha.getMinutes().toString();
                if(minutos.length<2) minutos = '0' + minutos;
                if(mes.length<2) mes = '0' + mes;
                if(dia.length<2) dia = '0' + dia;
                fechaToReturn = `${fecha.getFullYear()}-${mes}-${dia} ${hora}:${minutos}`;
            }
            else{
                fechaToReturn = '';
            }
            return fechaToReturn;
          } 

        let textoReturn : string = texto;
        if(textoReturn != '' && textoReturn != undefined){
            textoReturn = formatDate(new Date(textoReturn));
        }
        return textoReturn;
    }
}