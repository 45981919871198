import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { AngularFirestore } from 'angularfire2/firestore';
import Swal from 'sweetalert2';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class loginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth,
    private router: Router, private authService: AuthService, private angularFirestone: AngularFirestore) { }

  protected email: string = '';
  protected password: string = '';
  nombreDelUsuario: any = {}

  ngOnInit() { }

  protected getUsuario() { this.getNombreDeUsuario(this.email, this.password) }

  protected getNombreDeUsuario(correoDelUsuario: string,  usuarioPassword: string) {
    if (correoDelUsuario != undefined && correoDelUsuario != '') {
      this.query('usuarios', correoDelUsuario, usuarioPassword);
    }
  }

  protected query(collection: string, correoDelUsuario: string, usuarioPassword: string) {
    let self = this;
    let parametroDeWhere = 'correo';
    if (collection == 'empresas') { parametroDeWhere = 'correo1'; }
    this.angularFirestone.firestore.collection(collection).where(parametroDeWhere, "==", correoDelUsuario).where("contrasena", "==", usuarioPassword).get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        //LocalStorage
        if (doc.data().rol != 'EXTERNO') {
          self.nombreDelUsuario.nombre = doc.data().nombre;
          self.nombreDelUsuario.apaterno = doc.data().apaterno;
          self.nombreDelUsuario.amaterno = doc.data().amaterno;
          self.nombreDelUsuario.rol = doc.data().rol;
        }
        else {
          self.nombreDelUsuario.rol = doc.data().rol;
          self.nombreDelUsuario.nombre = doc.data().nombre;
        }
        //Termina LocalStorage
      });
      if (self.nombreDelUsuario.nombre != undefined && self.nombreDelUsuario.nombre != '') {
        self.setUsuario();
      }
      else {
        if(collection == 'usuarios'){
          self.query('empresas', correoDelUsuario, usuarioPassword);
        }
        else{
          alert('Acceso incorrecto, por favor revise su usuario y contraseña');
        }
      }
    })
  }

  protected setUsuario() {
    let usuario: any = {};
    if (this.nombreDelUsuario.rol != 'EXTERNO') {
      usuario = { email: this.email, nombre: this.nombreDelUsuario.nombre, apaterno: this.nombreDelUsuario.apaterno, amaterno: this.nombreDelUsuario.amaterno, rol: this.nombreDelUsuario.rol };
    }
    else {
      usuario = { email: this.email, nombre: this.nombreDelUsuario.nombre, rol: this.nombreDelUsuario.rol };
    }
    if (localStorage.getItem('usuario')) {
      localStorage.removeItem('usuario');
      localStorage.setItem('usuario', JSON.stringify(usuario));
    }
    else {
      localStorage.setItem('usuario', JSON.stringify(usuario));
    }
    this.onLoginRedirect();
  }

  onLogin(): void {
    this.getUsuario();
  }

  onLogout() {
    this.authService.logoutUser();
  }

  protected onLoginRedirect(): void {
    this.router.navigate(['pages/asuntos']);
  }


  alert() {
    let timerInterval
    Swal.fire({
      title: 'JL Abogados',
      html: 'Ingresando al sistema.',
      timer: 3700,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    })
  }
}
