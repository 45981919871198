import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'limitarcharPipe',
    pure: false
})
export class LimitarcharPipe implements PipeTransform {

    transform(texto: string='', caracteres:number=70) {
        let textoReturn : string = texto;
        if(textoReturn.length>caracteres){
            textoReturn = textoReturn.substr(0,caracteres);
            textoReturn += ' ...';
        }
        return textoReturn;
    }
}