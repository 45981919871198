import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'notificacionesactivasPipe',
    pure: false
})
export class NotificacionesactivasPipe implements PipeTransform {

    transform(notificacionesArr: any[]=[]): any {
        let notificacionesActivas : number = 0;
        if(notificacionesArr.length>0){
            notificacionesActivas = notificacionesArr.filter(notificacion => notificacion.fechaAbierto == "").length;
        }
        return notificacionesActivas;
    }
}